import {
  Grid,
  Paper,
  TextField,
  MenuItem,
  Button,
  Stack,
  Alert,
  AlertTitle,
} from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../Principal/Title";
import Copyright from "../Principal/Copyright";
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import { FormLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function CifrarDescifrar() {
  const [separator, setSeparator] = React.useState("E");
  const [linesToCifre, setLinesToCifre] = React.useState("");
  const [rows, setRows] = React.useState([]);
  var [openResult, setOpenResult] = React.useState(false);

  const [value, setValue] = useState("numbers");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loadingEncrypt, setLoadingEncrypt] = useState(false);
  const [loadingDecrypt, setLoadingDecrypt] = useState(false);

  const handleOptionChange = (e) => {
    setValue(e.target.value);
  };

  const handleLoadingEncrypted = (loading) => {
    setLoadingEncrypt(loading);
  };

  const handleLoadingDecrypted = (loading) => {
    setLoadingDecrypt(loading);
  };

  const [open, setOpen] = React.useState(false);
  var [messageError, setMessageError] = React.useState("");

  const handleClose = () => {
    setOpen(false);
    setOpenSuccess(false);
    setLoadingEncrypt(false);
    setLoadingDecrypt(false);
  };
  const onChangeSeparator = (e) => {
    setSeparator(e.target.value);
  };

  const onChangeLines = (event) => {
    setLinesToCifre(event.target.value);
    if (
      event.target.value.split(separator === "E" ? "\n" : separator).length >
      30000
    ) {
      setOpen(true);
      setMessageError("No se pueden procesar más de 30000 móviles");
      setLinesToCifre("");
    }
  };
  const onClickCifrar = () => {
    axios
      .post(
        process.env.REACT_APP_URL_API_ENCRYPTION + "/encrypt-lines",
        {
          lines: linesToCifre,
          typeSeparation: separator,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        var responseBack = response.data.data.decryptedLine.split("\n");
        var data = [];
        var textInputUser = linesToCifre.split(
          separator === "E" ? "\n" : separator
        );

        for (let index = 0; index < responseBack.length; index++) {
          var objectTemp = {};
          objectTemp["id"] = index;
          objectTemp["encrypted"] = responseBack[index];
          objectTemp["decrypted"] = textInputUser[index];
          data.push(objectTemp);
        }

        setRows(data);
        setLoadingEncrypt(false);
      })
      .catch(function (error) {
        setOpen(true);
        setMessageError(error.message);
      });
    setOpenResult(true);
  };

  const onClickDecifrar = () => {
    axios
      .post(
        process.env.REACT_APP_URL_API_ENCRYPTION + "/decrypt-lines",
        {
          lines: linesToCifre,
          typeSeparation: separator,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        var responseBack = response.data.data.decryptedLine.split("\n");
        var data = [];
        var textInputUser = linesToCifre.split(
          separator === "E" ? "\n" : separator
        );

        for (let index = 0; index < responseBack.length; index++) {
          var objectTemp = {};
          objectTemp["id"] = index;
          objectTemp["encrypted"] = textInputUser[index];
          objectTemp["decrypted"] = responseBack[index];
          data.push(objectTemp);
        }

        setRows(data);
        setLoadingDecrypt(false);
      })
      .catch(function (error) {
        setOpen(true);
        setMessageError(error.message);
      });
    setOpenResult(true);
  };

  const onClickGenerateEncryption = () => {
    axios
      .post(
        process.env.REACT_APP_URL_API_GOOGLESHEET + "/updateEncrypted",
        linesToCifre
      )
      .then((response) => {
        console.log(response);
        setLinesToCifre("");
        setOpenSuccess(true);
        setSuccessMessage("El documento ha sido cifrado");
      })
      .catch((error) => {
        setOpen(true);
        setMessageError(error.message);
      });
  };
  //
  const onClickGenerateDecryption = () => {
    axios
      .post(
        process.env.REACT_APP_URL_API_GOOGLESHEET + "/updateDecrypted",
        linesToCifre
      )
      .then((response) => {
        console.log(response);
        setLinesToCifre("");
        setOpenSuccess(true);
        setSuccessMessage("El documento ha sido descifrado");
      })
      .catch((error) => {
        setOpen(true);
        setMessageError(error.message);
      });
  };

  const tablaResponse = (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Title>Resultado</Title>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Cifrado</b>
              </TableCell>
              <TableCell>
                <b>Descifrado</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.encrypted}</TableCell>
                <TableCell>{row.decrypted}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Copyright />
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 320,
          }}
        >
          <Title>Cifrar/Descifrar</Title>
          <FormControl>
            <FormLabel> Escoge formato a cifrar </FormLabel>
            <RadioGroup
              row
              name="input-sheet"
              onChange={handleOptionChange}
              value={value}
            >
              <FormControlLabel
                value="numbers"
                control={<Radio />}
                label="Lineas"
              />
              <FormControlLabel
                value="spreadSheet"
                control={<Radio />}
                label="Google Sheet"
              />
            </RadioGroup>
          </FormControl>

          <TextField
            label={
              value === "numbers"
                ? "Ingrese los móviles"
                : "Ingrese la Url de Google Sheets"
            }
            variant="outlined"
            fullWidth
            multiline
            value={linesToCifre}
            onChange={onChangeLines}
            InputProps={{
              rows: 3,
            }}
            style={{
              width: "100%",
              height: "100%",
            }}
          />

          <Stack direction="row" spacing={2}>
            <TextField
              select
              label="Tipo separador"
              value={separator}
              onChange={onChangeSeparator}
              style={value === "numbers" ? null : { display: "none" }}
            >
              <MenuItem value="E">Salto de linea</MenuItem>
              <MenuItem value=";">;</MenuItem>
              <MenuItem value=",">,</MenuItem>
            </TextField>

            <LoadingButton
              size="small"
              onClick={() => {
                handleLoadingEncrypted(true);
                value === "numbers"
                  ? onClickCifrar()
                  : onClickGenerateEncryption();
              }}
              loading={loadingEncrypt}
              variant="contained"
              disabled={linesToCifre.trim().length === 0}
            >
              <span>Cifrar</span>
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={() => {
                handleLoadingDecrypted(true);
                value === "numbers"
                  ? onClickDecifrar()
                  : onClickGenerateDecryption();
              }}
              loading={loadingDecrypt}
              size="small"
              disabled={linesToCifre.trim().length === 0}
            >
              <span>Descifrar</span>
            </LoadingButton>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 320,
          }}
          style={{ overflowY: "auto" }}
        >
          <Title>Instrucciones</Title>
          <List color="text.secondary" sx={{ fontSize: 13 }}>
            <li>Debe escoger qué formato cifrar.</li>
            {value === "numbers" ? (
              <ul style={{ padding: "10px" }}>
                <li>
                  Debe seleccionar un tipo de separador para cada móvil (salto
                  de línea, ; o ,), por defecto está el salto de línea.
                </li>
                <li>El máximo de móviles no debe superar los 30.000.</li>
                <li>
                  Si el móvil a cifrar supera los 15 caracteres, no se cifrará.
                </li>
                <li>
                  Si el móvil a descifrar supera los 33 caracteres, no se
                  descifrará.
                </li>
              </ul>
            ) : (
              <ul style={{ padding: "10px" }}>
                <li>Abra el enlace del archivo de hoja de cálculo.</li>
                <li>
                  Asegúrese de que los valores a cifrar o descifrar estén todos
                  en la columna A.
                </li>
                <li>
                  En la parte superior derecha encontrará un botón para
                  compartir. Tendrá que dar acceso para compartir.
                </li>
                <li>
                  Una vez que haga clic en compartir, aparecerá un menú en la
                  pantalla, vaya a la parte de acceso general.
                </li>
                <li>
                  Escoja la opción "Público: cualquiera con el enlace puede
                  editar", verifique que la opción de editor esté seleccionada.
                </li>
                <li>
                  Por último, haga clic en el botón "Compartir enlace" y pegue
                  el enlace que ha sido generado en la caja de texto.
                </li>
              </ul>
            )}
          </List>
        </Paper>
      </Grid>
      {openResult ? tablaResponse : ""}
      <Modal open={open} onClose={handleClose}>
        <Alert severity="error" sx={style}>
          <AlertTitle>Upss!!!</AlertTitle>
          {messageError}
        </Alert>
      </Modal>

      <Modal open={openSuccess} onClose={handleClose}>
        <Alert severity="success" sx={style}>
          <AlertTitle> Exitoso!</AlertTitle>
          {successMessage}
        </Alert>
      </Modal>
    </Grid>
  );
}
