import { Box, Typography } from "@mui/material";
import { useState } from "react";
import Copyright from "../Principal/Copyright";
import Title from "../Principal/Title";

export default function Home() {
  var user = {};
  if (localStorage.getItem("usuario") !== null) {
    user = localStorage.getItem("usuario")  || {};
  }
  var [userInfo] = useState(user);

  return (
    <Box>
      <img src="images/fonyou_black.svg" alt="" />
      <Title>Bienvenido Al Portal Autogestión </Title>
      <Typography>
        Hola {userInfo.name}, te doy la bienvenida al portal de Autogestion de
        fonYou, espero te sientas muy comodo en la aplicacion, acontinuación
        algunas instrucciones para que te guies un poco: A tu lado izquierdo
        encontraras el menú donde puedes navegar por las distintas funciones que
        tenemos para ti, en la parte superior derecha tendras tu perfil y donde
        puedes cerrar sesión.
      </Typography>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          pb: 2,
          left: 0,
          width: "100%",
        }}
      >
        <Copyright />
      </Box>
    </Box>
  );
}
