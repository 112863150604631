import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Layout from "./Components/Principal/Layout";
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  let location = useLocation();

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return <Layout />;
  }
}

export default App;
