import { positions, textAlign } from "@mui/system";
import React from "react";

export default function NotFound() {
  return (
    <div align="center">
      <img src="images/404.png"  alt="404" width="800" height="400"/>
    </div>
  );
}
