import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { deepOrange } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import Copyright from "../Principal/Copyright";
import Title from "../Principal/Title";
import axios from "axios";
import { GoogleLogin } from '@react-oauth/google';

export default function SignIn() {
  let navigate = useNavigate();
  var [errorIngresar, seterrorIngresar] = useState(false);
  var [message, setMessage] = useState("");

  //Logica para el inicio de sesión con google
  const botonGoogle = (renderProps) => (
    <Button
      startIcon={<GoogleIcon />}
      onClick={renderProps.onClick}
      disabled={renderProps.desabled}
      fullWidth
      sx={{ mt: 3 }}
    >
      Iniciar Sesión Con Google
    </Button>
  );
  const onSuccess = (res) => {
    const response = { ...res }
    localStorage.setItem("token", response.credential);
    axios
      .post(
        process.env.REACT_APP_URL_API_AUTH + "/save-token",
        {
          accessToken: localStorage.getItem("token"),
          expiresIn: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        navigate("/");
      })
      .catch(function (error) {
        seterrorIngresar(true);
        setMessage("Error en la api " + error.message);
        localStorage.setItem("token", "");
      });
  };
  const onFailure = () => {
    seterrorIngresar(true);
    setMessage("El usuario es Incorrecto");
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ bgcolor: deepOrange[500], width: 56, height: 56 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Title>Iniciar Sesión</Title>
        <Typography>
          Bienvenido al portal de Autogestión <b>fonYou</b>
        </Typography>
        <GoogleLogin onSuccess={onSuccess} onError={onFailure} />
        {errorIngresar === true && (
          <Alert severity="error">Upss! — {message}</Alert>
        )}
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
