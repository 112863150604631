import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Pages/Home";
import NotFound from "./Components/Pages/NotFound";
import CifrarDescifrar from "./Components/Pages/CifrarDescifrar";
import SignIn from "./Components/Login/SignIn";
import Layout from "./Components/Principal/Layout";
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="prueba" element={<Layout />} />
            <Route path="cifrar-descifrar" element={<CifrarDescifrar />} />          
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route exact path="login" element={<SignIn />} />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
