import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import ListItemText from "@mui/material/ListItemText";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function ItemsMenu(props) {
  const navigate = useNavigate();

  const irCifrarDescifrar = () => {
    navigate("/cifrar-descifrar");
  };
  const irHome = () => {
    navigate("/");
  };
  return (
    <React.Fragment>
      <ListItemButton onClick={irHome}>
        <ListItemIcon>
          <HomeRoundedIcon />
        </ListItemIcon>
        <ListItemText>Home</ListItemText>
      </ListItemButton>
      <Divider sx={{ my: 1 }} />
      <ListItemButton onClick={irCifrarDescifrar}>
        <ListItemIcon>
          <HttpsRoundedIcon />
        </ListItemIcon>
        <ListItemText>Cifrar/Descifrar</ListItemText>
      </ListItemButton>
      
    </React.Fragment>
  );
}
